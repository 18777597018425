<template>
  <section>
    <TitleHead title="优惠券模块"> </TitleHead>
    <el-divider></el-divider>
    <div>
      <div class="xa-txt-14 xa-mgb-16">
        创建优惠券
      </div>
      <el-row :gutter="8">
        <el-col :span="8">
          <el-card shadow="hover">
            <router-link class="xa-cursor xa-txt-blue" :to="'./form/create/2'">
              单品券</router-link
            >
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-divider></el-divider>
    <div>
      <div class="xa-txt-14 xa-mgb-16">
        发券
      </div>
      <el-row :gutter="8">
        <el-col :span="8">
          <el-card shadow="hover">
            <router-link
              class="xa-cursor xa-txt-blue"
              :to="'./form/distribution/batch'"
            >
              批量发券</router-link
            >
          </el-card>
        </el-col>
      </el-row>
    </div>
  </section>
</template>
<script>
import TitleHead from '@/components/comHead/TitleHead'
export default {
  components: {
    TitleHead,
  },
}
</script>
<style lang="postcss" scoped></style>
